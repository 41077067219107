.navbar {
  width: 100%;
  //   height: 3.5rem;

  background: var(--bg_color);

  padding: 0.7rem 1.44rem;

  .logo {
    width: 6.25rem;
    height: auto;

    object-fit: contain;
  }

  @media screen and (min-width: 400px) {
    // height: 3.79rem;
    padding: 0.74481rem 1.55731rem;

    .logo {
      width: 6.8rem;
    }
  }

  @media screen and (min-width: 730px) {
    // height: 3.5rem;
    padding: 0.6875rem 1.4375rem;

    .logo {
      width: 6.25rem;
    }
  }

  @media screen and (min-width: 1000px) {
    // height: 4.29738rem;
    padding: 0.84413rem 1.765rem;

    .logo {
      width: 7.7rem;
    }
  }

  @media screen and (min-width: 1400px) {
    // height: 6.04319rem;
    padding: 1.18706rem 2.482rem;

    .logo {
      width: 10.8rem;
    }
  }

  @media screen and (min-width: 1700px) {
    // height: 8.05756rem;
    padding: 1.58275rem 3.30938rem;

    .logo {
      width: 14.4rem;
    }
  }
}
