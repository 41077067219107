.zunu_ai_section {
  display: flex;
  flex-direction: column;
  align-items: center;

  background: var(--bg_color);

  padding: 8.28rem 0;

  .zunu_ai_article {
    width: 90%;

    margin-bottom: 4.4rem;

    h3 {
      color: #6093fd;
      text-align: center;
      font-family: Poppins;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      margin-bottom: 0.5rem;
    }

    p {
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &:first-of-type {
        margin-bottom: 1.5rem;
      }
    }
  }

  .zunu_ai_cs {
    margin-bottom: 8rem;

    h3 {
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 2rem;
      font-style: normal;
      font-weight: 900;
      line-height: normal;

      text-transform: uppercase;
    }
  }

  .zunu_ai_form {
    display: flex;
    flex-direction: column;
    gap: 1.1rem;

    .zunu_ai_form_title {
      color: #d6d6d6;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .zunu_ai_input {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      border-bottom: 1px solid #524f78;

      padding: 0.5rem 0;

      color: #524f78;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      .zunu_ai_input_icon {
        width: 1.5rem;
        height: 1.5rem;
      }

      input {
        background: none;
        border: none;

        color: #d6d6d6;
        font-size: 0.875rem;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;

        &::placeholder {
          color: #524f78;
        }

        &:focus {
          outline: none;
        }
      }

      button {
        color: #524f78;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        border: none;
        background: none;

        cursor: pointer;
      }
    }
  }

  @media screen and (min-width: 400px) {
    .zunu_ai_article {
      h3 {
        font-size: 2.70831rem;
      }

      p {
        font-size: 1rem;

        &:first-of-type {
          margin-bottom: 1.63rem;
        }
      }
    }

    .zunu_ai_form {
      gap: 1.69rem;

      .zunu_ai_form_title {
        font-size: 1.08331rem;
      }

      .zunu_ai_input {
        gap: 0.54;
        font-size: 0.94794rem;
        padding: 0.54rem 0;

        .zunu_ai_input_icon {
          width: 1.625rem;
          height: 1.625rem;
        }

        input,
        button {
          font-size: 0.94794rem;
        }
      }
    }
  }

  @media screen and (min-width: 730px) {
    .zunu_ai_article {
      margin-bottom: 5.8rem;

      h3 {
        font-size: 4rem;
      }

      p,
      input::placeholder {
        font-size: 1rem;
      }

      p {
        font-size: 1rem;

        &:first-of-type {
          margin-bottom: 0.5rem;
        }
      }
    }

    .zunu_ai_cs {
      margin-bottom: 10.5rem;

      h3 {
        font-size: 4rem;
      }
    }

    .zunu_ai_form {
      gap: 1.69rem;
      width: 70%;

      .zunu_ai_form_title {
        font-size: 1.08331rem;
      }

      .zunu_ai_input {
        gap: 0.54;
        font-size: 0.875rem;

        input {
          width: 80%;
        }

        input,
        button {
          font-size: 0.875rem;
        }
      }
    }
  }

  @media screen and (min-width: 1000px) {
    .zunu_ai_article {
      h3 {
        font-size: 3.68344rem;
      }

      p {
        font-size: 1.22781rem;

        &:first-of-type {
          margin-bottom: 0.6rem;
        }
      }
    }

    .zunu_ai_cs {
      h3 {
        font-size: 4.91125rem;
      }
    }

    .zunu_ai_form {
      gap: 1.5rem;
      width: 70%;

      .zunu_ai_input {
        gap: 0.67rem;
        font-size: 1.16388rem;
        padding: 0.67rem 0;

        .zunu_ai_input_icon {
          width: 2rem;
          height: 2rem;
        }

        input {
          width: 80%;
        }

        input,
        button {
          font-size: 1rem;
        }
      }
    }
  }

  @media screen and (min-width: 1400px) {
    padding: 6rem 0;

    .zunu_ai_article {
      margin-bottom: 3rem;

      h3 {
        font-size: 3rem;
      }

      p {
        font-size: 1.125rem;
      }
    }

    .zunu_ai_cs {
      margin-bottom: 7rem;

      h3 {
        font-size: 3.375rem;
        letter-spacing: 1.38375rem;
      }
    }

    .zunu_ai_form {
      gap: 2.1rem;
      width: 60%;
      align-items: center;

      .zunu_ai_form_title {
        font-size: 1rem;
      }

      .zunu_ai_input {
        gap: 0.94rem;
        font-size: 1rem;
        padding: 1rem 0;

        width: inherit;

        .zunu_ai_input_icon {
          width: 2.8rem;
          height: 2.8rem;
        }

        input {
          width: 70%;
          font-size: 1rem;
        }

        button {
          font-size: 1.125rem;
        }
      }
    }
  }

  @media screen and (min-width: 1700px) {
    padding: 4rem 0;

    .zunu_ai_article {
      margin-bottom: 4.5rem;

      h3 {
        font-size: 4rem;
      }

      p {
        font-size: 2rem;
      }
    }

    .zunu_ai_cs {

      h3 {
        font-size: 4.5rem;
        letter-spacing: 1.845rem;
      }
    }

    .zunu_ai_form {
      gap: 2.1rem;

      width: 70%;

      .zunu_ai_form_title {
        font-size: 1.25rem;
      }

      .zunu_ai_input {
        gap: 1.5rem;
        font-size: 2.18225rem;
        padding: 1.25rem 0;

        width: inherit;

        .zunu_ai_input_icon {
          width: 3.741rem;
          height: 3.741rem;
        }

        input {
          width: 72%;
          font-size: 1.25rem;
        }

        button {
          font-size: 1.5rem;
        }
      }
    }
  }
}
