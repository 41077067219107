:root {
  --bg_color: #0e0d1c;
}

* {
  transition: all 200ms ease-in-out;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--bg_color);
}

body {
  width: 100%;
  line-height: normal;
  font-family: Poppins;
  overflow-x: hidden;
  max-width: 2500px;

  min-height: 100vh;
}

.App {
  width: 100vw;
  min-height: 100vh;
}
